<template>
    <div class="PersonalCenter">
        <div class="c-title">
            <h1>a-puhaha</h1>
            <p>恋爱是为了开心，选择不爱也是。</p>
        </div>
        <!-- 内容输出部分 -->
        <div class="c-wrap">
            <div class="person-con">暂未开放，有事联系2642376607@qq.com</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PersonalCenter',

        data() {
            return {

            };
        },

        mounted() {

        },

        methods: {

        },
    };
</script>

<style lang="less" scoped>
    .PersonalCenter {
        position: relative;
        min-height: calc(100vh - 50px);
        background-color: #f1f3f4;
        .c-title {
            box-sizing: border-box;
            height: 100px;
            padding: 20px 20px 0;
            background-color: #f9f9f9;
            h1 {
                font-weight: 200;
            }
            p {
                margin-top: 5px;
                letter-spacing: 2px;
                color: #777;
                font-size: 13px;
            }
        }
        .c-wrap {
            padding: 20px;
            //
            .person-con {
                min-height: 80vh;
                border-radius: 4px;
                padding: 32px;
                margin-bottom: 50px;
                background-color: #fff;
            }
        }
    }
</style>